<template>
    <div :class="$style['home']">
        <div :class="[$style['calculator-form'], $style['govuk-grid-row']]">
            <div :class="$style['govuk-grid-column-full']">
                <div
                    :class="$style['govuk-notification-banner']"
                    id="grade-result"
                    aria-labelledby="govuk-notification-banner-title"
                    v-if="hasSubmitted"
                    data-module="govuk-notification-banner"
                >
                    <div :class="$style['govuk-notification-banner__header']">
                        <h2
                            :class="$style['govuk-notification-banner__title']"
                            id="govuk-notification-banner-title"
                        >
                            Your grade
                        </h2>
                    </div>
                    <div
                        :class="[
                            $style['govuk-notification-banner__content'],
                            $style['grade-result-banner'],
                        ]"
                    >
                        <div
                            :class="$style['govuk-grid-row']"
                            v-if="calculationCopy.percentageSum > 100"
                        >
                            <div :class="$style['govuk-grid-column-full']">
                                <p :class="$style['govuk-error-message']">
                                    <span
                                        :class="$style['govuk-visually-hidden']"
                                        >Error:</span
                                    >
                                    Did you mean to provide assessments
                                    totalling more than 100%?
                                </p>
                            </div>
                        </div>
                        <div :class="$style['govuk-grid-row']">
                            <div :class="$style['govuk-grid-column-one-third']">
                                <p
                                    :class="[
                                        $style['govuk-body'],
                                        $style['gradeAverage'],
                                    ]"
                                >
                                    {{ calculationCopy.weightedAverageOneDP }}
                                </p>
                            </div>
                            <div
                                :class="$style['govuk-grid-column-two-thirds']"
                            >
                                <p
                                    :class="[
                                        $style['govuk-body'],
                                        $style['gradeSummary'],
                                    ]"
                                >
                                    Your
                                    <strong
                                        >average mark was
                                        {{
                                            calculationCopy.weightedAverageOneDP
                                        }}</strong
                                    >. You provided assessments worth
                                    <span
                                        :class="{
                                            'mood-red':
                                                calculationCopy.percentageSum >
                                                100,
                                        }"
                                        >{{
                                            calculationCopy.percentageSum
                                        }}%</span
                                    >
                                    in total.
                                </p>
                            </div>
                        </div>
                        <div
                            :class="$style['govuk-grid-row']"
                            v-if="
                                useTargetGrade &&
                                calculationCopy.targetGrade > 0
                            "
                        >
                            <div :class="$style['govuk-grid-column-full']">
                                <hr
                                    :class="
                                        $style[
                                            'govuk-section-break govuk-section-break--m govuk-section-break--visible'
                                        ]
                                    "
                                />
                                <template
                                    v-if="
                                        calculationCopy.targetGradeRequires < 0
                                    "
                                >
                                    <p :class="$style['govuk-body']">
                                        <strong>You can score 0</strong> on your
                                        remaining assessments and you'll still
                                        score your target grade of
                                        {{ calculationCopy.targetGrade }}
                                        overall.
                                    </p>
                                </template>
                                <template
                                    v-if="
                                        calculationCopy.targetGradeRequires > 0
                                    "
                                >
                                    <p :class="$style['govuk-body']">
                                        <strong
                                            >To reach your target grade</strong
                                        >
                                        of {{ calculationCopy.targetGrade }}
                                        <strong
                                            >you need
                                            {{
                                                calculationCopy.targetGradeRequires
                                            }}%</strong
                                        >
                                        in the remaining
                                        {{
                                            calculationCopy.percentageRemaining
                                        }}% of assessments.
                                    </p>
                                </template>
                            </div>
                        </div>
                        <!-- <pre>{{ calculationCopy }}</pre>-->
                        <!-- <pre>{{ calculation }}</pre>
                        <pre>{{ grades }}</pre> -->
                    </div>
                </div>

                <InputRow
                    :number="rows"
                    :focus="focusFirstInput"
                    @grade-changed="gradeChanged"
                ></InputRow>
            </div>
        </div>
        <div class="advert">
            <!-- V2__Above_buttons -->
            <ins
                class="adsbygoogle"
                style="display: block"
                data-ad-client="ca-pub-3762143796223855"
                data-ad-slot="9872390780"
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
        <div :class="$style['govuk-grid-row']">
            <div :class="$style['govuk-grid-column-one-half']">
                <div
                    :class="[
                        $style['govuk-form-group'],
                        $style['calculator-form-style'],
                    ]"
                    v-if="useTargetGrade"
                >
                    <label
                        :class="$style['govuk-label']"
                        for="weight"
                    >
                        Tell me what I need to achieve...
                    </label>
                    <div :class="$style['govuk-input__wrapper']">
                        <input
                            :class="[
                                $style['govuk-input'],
                                $style['govuk-input--width-3'],
                            ]"
                            type="number"
                            spellcheck="false"
                            v-model.number="targetGrade"
                        />
                        <div
                            :class="$style['govuk-input__suffix']"
                            aria-hidden="true"
                        >
                            overall
                        </div>
                    </div>
                </div>
                <p :class="$style['govuk-body']">
                    <span v-if="!useTargetGrade"
                        ><a
                            href="#"
                            :class="$style['govuk-link']"
                            @click.prevent="addTargetGrade"
                            >+ Add an overall target grade</a
                        ><br
                    /></span>
                    <a
                        href="#"
                        :class="$style['govuk-link']"
                        @click.prevent="addInputRow"
                        >+ Add another mark</a
                    >
                </p>
            </div>
            <div :class="$style['govuk-grid-column-one-half']">
                <button
                    :class="[$style['govuk-button'], $style['calculate']]"
                    ref="submitButton"
                    data-module="govuk-button"
                    @click="submit"
                >
                    Calculate grade&nbsp; 🚀
                </button>
            </div>
        </div>

        <div :class="$style['govuk-grid-row']">
            <div :class="$style['govuk-grid-column-one-half']">
                <h2 :class="$style['govuk-heading-m']">How does it work?</h2>
                <p :class="$style['govuk-body']">
                    This tools helps you quickly work out your average grade
                    across modules and for your degree overall.
                </p>

                <div class="advert">
                    <ins
                        class="adsbygoogle"
                        style="display: block; text-align: center"
                        data-ad-layout="in-article"
                        data-ad-format="fluid"
                        data-ad-client="ca-pub-3762143796223855"
                        data-ad-slot="1967610915"
                    ></ins>
                </div>
                <p :class="$style['govuk-body']">
                    Knowing this can help you achieve a better degree by being
                    aware of what grades you need to get in an assesment, or
                    even simply put your mind at rest that you're on track.
                </p>
                <!--<p :class="$style['govuk-body']">Every university works out degree classifications a little differently. For this reason, unigradecalc has been designed so you can quickly calculate weighted averages without having to know too much about marking policies.</p>-->
            </div>

            <div :class="$style['govuk-grid-column-one-half']">
                <h3 :class="$style['govuk-heading-m']">Example</h3>
                <p :class="$style['govuk-body']">
                    <em>Module A</em> has of four marked assessments, one worth
                    70% and three worth 10% each.
                </p>
                <p :class="$style['govuk-body']">
                    Knowing the grades of the 70% assessment, and one 10%
                    assessment it is possible to calculate your average grade so
                    far over 80% of this module by inputting your results as
                    follows.
                </p>

                <table :class="$style['govuk-table']">
                    <thead :class="$style['govuk-table__head']">
                        <tr :class="$style['govuk-table__row']">
                            <th
                                scope="col"
                                :class="[
                                    $style['govuk-table__header'],
                                    $style['app-custom-class'],
                                ]"
                            >
                                Mark
                            </th>
                            <th
                                scope="col"
                                :class="[
                                    $style['govuk-table__header'],
                                    $style['app-custom-class'],
                                ]"
                            >
                                Percentage
                            </th>
                            <th
                                scope="col"
                                :class="[
                                    $style['govuk-table__header'],
                                    $style['app-custom-class'],
                                ]"
                            >
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody :class="$style['govuk-table__body']">
                        <tr :class="$style['govuk-table__row']">
                            <td :class="$style['govuk-table__cell']">65</td>
                            <td :class="$style['govuk-table__cell']">70%</td>
                            <td :class="$style['govuk-table__cell']">
                                Long essay
                            </td>
                        </tr>
                        <tr :class="$style['govuk-table__row']">
                            <td :class="$style['govuk-table__cell']">72</td>
                            <td :class="$style['govuk-table__cell']">10%</td>
                            <td :class="$style['govuk-table__cell']">
                                Literature review
                            </td>
                        </tr>
                        <tr :class="$style['govuk-table__row']">
                            <td
                                :class="$style['govuk-table__cell']"
                                colspan="3"
                            >
                                Average mark over 80% = 65.9
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h4 :class="$style['govuk-heading-s']">Predictions?</h4>
                <p :class="$style['govuk-body']">
                    Have a guess at what you'll get in the remaining two
                    assessments worth 20%? See the impact by entering those
                    grades too.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import InputRow from "@/components/InputRow.vue";

export default {
    name: "HomeView",
    components: {
        InputRow,
    },
    data() {
        return {
            rows: 5,
            grades: null,
            calculation: { weightedAverage: null, percentageSum: null },
            // Store a copy so adjusted inputs dont' modify the calcaulation until submit
            calculationCopy: { weightedAverage: null, percentageSum: null },
            hasSubmitted: false,
            focusFirstInput: false,
            useTargetGrade: false,
            targetGrade: null,
        };
    },
    methods: {
        addInputRow() {
            this.rows++;
            // this.$gtag.event('add-input', {
            //     'event_category': 'buttons',
            //     'event_label': 'An input box was added',
            // })
            this.trackEvent("add-input");
        },
        addTargetGrade() {
            this.useTargetGrade = true;

            // this.$gtag.event('target-calculated', {
            //     'event_category': 'buttons',
            //     'event_label': 'The target grade feature was used',
            // })
            this.trackEvent("grade-target");
        },
        gradeChanged(event) {
            this.grades = event;
        },
        calculateGrades() {
            this.grades = this.grades || [];
            this.grades.forEach((e, i, arr) => {
                // Type check
                let percentage = Number(arr[i].percentage);
                let mark = Number(arr[i].mark);

                // @todo seperate
                if (percentage > 0 && mark > 0) {
                    arr[i].weightSum =
                        Number(arr[i].percentage) * Number(arr[i].mark);
                } else {
                    arr[i].weightSum = 0;
                }
            });

            this.calculation.percentageSum = this.grades.reduce(
                (sum, e) => sum + Number(e.percentage),
                0
            );
            this.calculation.weightedAverage =
                this.grades.reduce((sum, e) => sum + Number(e.weightSum), 0) /
                this.calculation.percentageSum;

            // Round
            this.calculation.weightedAverageOneDP =
                Math.round(this.calculation.weightedAverage * 10) / 10;
            this.calculation.weightedAverageTwoDP =
                Math.round(this.calculation.weightedAverage * 100) / 100;

            // For support only
            this.calculation.percentageRemaining =
                100 - this.calculation.percentageSum;

            // Target grade calculation
            if (this.useTargetGrade) {
                this.calculation.targetGrade = this.targetGrade;
                this.calculation.targetGradeRequires =
                    (this.targetGrade * 100 -
                        this.calculation.weightedAverage *
                            this.calculation.percentageSum) /
                    (100 - this.calculation.percentageSum);
                this.calculation.targetGradeRequires =
                    Math.round(this.calculation.targetGradeRequires * 100) /
                    100;
            }
        },
        submit() {
            this.calculateGrades();

            // this.$gtag.event('grade-calculated', {
            //     'event_category': 'buttons',
            //     'event_label': 'A grade was calculated',
            // })

            if (
                this.calculation.percentageSum > 0 &&
                this.calculation.weightedAverage > 0
            ) {
                // Needs a deep copy if object becomes nested
                this.calculationCopy = { ...this.calculation };
                this.hasSubmitted = true;
                document.activeElement.blur();

                this.$scrollTo("#grade-result-anchor");

                this.trackEvent("grade-calculated", {
                    grade: this.calculation.weightedAverage,
                });

                return;
            }

            this.focusFirstInput = true;
        },
        adsenseAddLoad() {
            let inlineScript = document.createElement("script");
            inlineScript.type = "text/javascript";
            inlineScript.text =
                "(adsbygoogle = window.adsbygoogle || []).push({});";
            document.getElementsByTagName("body")[0].appendChild(inlineScript);
        },
        trackEvent(eventName, data = {}) {
            if (window.umami && typeof window.umami.track === "function") {
                window.umami.track(eventName, data);
            }
        },
    },
    mounted() {
        // Disbale adsense for now
        // this.adsenseAddLoad();
    },
};
</script>

<style lang="scss" module>
.x {
}
</style>
