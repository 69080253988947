<template>

    <div v-for="(e, index) in number" :key="index">
        <div :class="$style['govuk-grid-row']">
            <div :class="$style['govuk-grid-column-one-quarter']">
                <div :class="$style['govuk-form-group']">
                    <label :class="[$style['govuk-label'], $style['label-l']]" v-if="index == 0">Mark</label>
                    <input :class="[$style['govuk-input'], $style['govuk-!-width-full']]"
                        v-model.number="grade[index].mark" type="number" placeholder="0 - 100" :ref="'mark' + index">
                </div>

            </div>
            <div :class="$style['govuk-grid-column-one-quarter']">
                <div :class="$style['govuk-form-group']">
                    <label :class="[$style['govuk-label'], $style['label-l']]" v-if="index == 0">
                        <span :class="$style['label-long']">Percentage</span>
                        <span :class="$style['label-short']">%</span>
                    </label>
                    <div :class="$style['govuk-input__wrapper']"><input
                            :class="[$style['govuk-input'], $style['input-weight'], $style['govuk-!-width-full']]"
                            v-model.number="grade[index].percentage" placeholder="%" type="number">
                        <div :class="$style['govuk-input__suffix']" aria-hidden="true">%</div>
                    </div>
                </div>
            </div>
            <div :class="$style['govuk-grid-column-one-half']">
                <div :class="$style['govuk-form-group']">
                    <label :class="[$style['govuk-label'], $style['label-l']]" v-if="index == 0">
                        <span :class="$style['label-long']">Assessment name</span>
                        <span :class="$style['label-short']">Name</span>
                    </label>
                    <input :class="[$style['govuk-input'], $style['govuk-!-width-full']]" v-model="grade[index].name"
                        type="text">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputRow',
    props: {
        number: Number,
        focus: Boolean
    },
    emits: ['grade-changed'],
    data() {
        return {
            // 100 to avoid issues when adding new inputs
            grade: new Array(100).fill().map(() => { return { mark: null, percentage: null, name: null } })
        }
    },
    watch: {
        grade: {
            handler: function (newValue) {
                this.$emit('grade-changed', newValue);
            },
            deep: true
        },
        focus: {
            handler: function (newValue) {
                if (!newValue) {
                    return
                }

                this.$refs.mark0[0].focus()
            }
        }
    },
    created() {
        // Test data 
        // this.grade = [
        //     { mark: 70, percentage: 40, name: 'Long essay' },
        //     { mark: 65, percentage: 25, name: 'Short essay' },
        //     // { mark: 55, percentage: 36, name: 'Exam'},
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        //     { mark: null, percentage: null, name: null },
        // ]
    }
}
</script>

<style lang="scss" module>
.x {}
</style>
    