import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        meta: { title: 'unigradecalc - About' },
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        meta: { title: 'unigradecalc - Privacy policy' },
        component: () => import('../views/PrivacyPolicy.vue')
    },
    {
        path: '/404',
        name: 'HttpStatus404',
        meta: { title: 'unigradecalc - 404' },
        component: () => import('../views/HttpStatus404.vue')
    },
    { 
        path: '/:pathMatch(.*)*', 
        meta: { title: 'unigradecalc - 404' },
        component: () => import('../views/HttpStatus404.vue') 
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        // return { top: 0 }
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
})

export default router
