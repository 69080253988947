<template>
    <header>
        <div :class="$style['govuk-width-container']">
            <div :class="$style['govuk-grid-row']">
                <div :class="$style['govuk-main-wrapper']">

                    <div :class="$style['govuk-grid-row']">
                        <div :class="$style['govuk-grid-column-two-thirds']">
                            <h1 :class="$style['logo']"><a href="/"><span :class="$style['block']">u</span>nigradecalc</a></h1>
                        </div>
                    </div>

                    <div :class="$style['govuk-grid-row']">
                        <div :class="$style['govuk-grid-column-full']">
                            <h2 :class="$style['tagline']">Calculate your module average or degree classification.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div :class="$style['govuk-width-container']" id="grade-result-anchor">
        <div :class="$style['govuk-grid-row']">
            <main :class="$style['govuk-main-wrapper']">
                <router-view :key="$route.fullPath"></router-view>
            </main>
        </div>
    </div>
    <footer :class="$style['govuk-footer']" role="contentinfo">
        <div :class="$style['govuk-width-container']">
            <div :class="$style['govuk-footer__meta']">
                <div :class="[$style['govuk-footer__meta-item'], $style['govuk-footer__meta-item--grow']]">
                    <!----><p :class="$style['govuk-body']">Over 5,000,000 grade
                        calculations since 2010&nbsp; 🎉</p>

                    <span :class="$style['govuk-footer__licence-description']">The calculations on this page are mathematical only, they may not reflect your university's marking policy.
                    </span>

                    <ul :class="$style['govuk-footer__inline-list']">
                        <li :class="$style['govuk-footer__inline-list-item']">
                            <router-link :class="$style['govuk-footer__link']" to="/">Home</router-link>
                        </li>
                        <li :class="$style['govuk-footer__inline-list-item']">
                            <router-link :class="$style['govuk-footer__link']" to="/privacy-policy">Privacy policy</router-link>
                        </li>
                        <li :class="$style['govuk-footer__inline-list-item']">
                            <router-link :class="$style['govuk-footer__link']" to="/about">About</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>

</template>

<style lang="scss" module>
.x {}
</style>

<script>
export default {
    watch: {
      '$route' (to) {
        document.title = to.meta.title || 'unigradecalc - University Grade Calculator'
        // Update canonical with full url. Enforce https at DNS/CDN level
        document.querySelector('link[rel="canonical"]').href = new URL(to.href, 'https://unigradecalc.com').href;

      }
    },
}
</script>

